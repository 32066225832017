import * as React from "react";
import { Typography, Grid } from "@mui/material";
import { StaticImage } from "gatsby-plugin-image";

// components
import Page from "../utils/page";
import Hero from "../utils/hero";
import theme from "../utils/theme";
import Section from "../utils/section";
import ServiziCard from "../components/servizi-card";

// markup
const Aree = () => {
    return (
        <Page
            title="Le nostre Aree"
            description="Il nostro obiettivo è offrire le soluzioni migliori personalizzando il consiglio. Dermocosmesi, Dietetica, Omeopatia e molto altro."
        >
            <Hero
                color="#fff"
                title="Le nostre Aree"
                description="Il nostro obiettivo è offrire le soluzioni migliori personalizzando il consiglio."
                background={theme.palette.primary.main}
                backgroundImage={
                    <StaticImage
                        style={{ height: "100%" }}
                        quality={100}
                        src="../images/farmacia-settimo-miglio-cosmesi.jpg"
                        alt="Farmacia Settimo Miglio servizi"
                        layout="fullWidth"
                    />
                }
            />

            <Section>
                <Grid container spacing={5}>
                    <Grid item xs={12} md={6}>
                        <Typography variant="h4" gutterBottom component="h2" color="primary">
                            Dermocosmesi e Igiene
                        </Typography>
                        <Typography>
                            Prodotti selezionati per il <strong>benessere dermatologico</strong> dei pazienti.{" "}
                            <strong>Ascoltiamo</strong> le tue esigenze per consigliarti il prodotto migliore. Alcune
                            delle nostre linee: Farmacisti preparatori Unifarco, Filorga, Pura Vida Bio, Rilastil,
                            Ceramol, Vichy, Euphidra.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography variant="h4" gutterBottom component="h2" color="primary">
                            Dietetica e Sport
                        </Typography>
                        <Typography>
                            Prodotti <strong>senza glutine</strong>, prodotti per diete aproteiche, pasti sostitutivi e{" "}
                            <strong>integratori alimentari</strong> di vario tipo. Sono presenti prodotti specifici per
                            lo sport.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography variant="h4" gutterBottom component="h2" color="primary">
                            Omeopatia
                        </Typography>
                        <Typography>
                            Storico <strong>reparto omeopatico</strong> e <strong>floriterapico</strong> completo e ben
                            organizzato, seguito da specialisti che sono a totale disposizione dei pazienti anche per{" "}
                            <strong>consulenze personalizzate</strong>.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography variant="h4" gutterBottom component="h2" color="primary">
                            Fitoterapia
                        </Typography>
                        <Typography>
                            Integratori e farmaci fitoterapici. Siamo inoltre qualificati come{" "}
                            <strong>Farmacia Apoteca Natura</strong>, offrendo quindi oltre ad un vasto assortimento
                            anche servizi di prevenzione.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography variant="h4" gutterBottom component="h2" color="primary">
                            Sanitaria – Ortopedia
                        </Typography>
                        <Typography>
                            Prodotti sanitari e ortopedici di vario tipo come stampelle, tutori, carrozzine,
                            deambulatori, aerosol, tiralatte e altri ancora (in vendita o noleggio). Ausili sanitari in{" "}
                            <strong>convenzione con SSN</strong>.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography variant="h4" gutterBottom component="h2" color="primary">
                            Veterinaria
                        </Typography>
                        <Typography>
                            Farmaci e prodotti per la <strong>cura e il benessere dell'animale</strong> anche
                            nell'ambito dei prodotti naturali (integratori, fiori di Bach, essenze australiane).
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography variant="h4" gutterBottom component="h2" color="primary">
                            Galenica
                        </Typography>
                        <Typography>
                            È presente un <strong>laboratorio specifico</strong> per l'allestimento di medicinali
                            galenici personalizzati per uso orale, dermatologico e veterinario.
                        </Typography>
                    </Grid>
                </Grid>
            </Section>

            <Section>
                <Grid container spacing={6}>
                    <Grid item xs={12} md={6}>
                        <ServiziCard />
                    </Grid>
                </Grid>
            </Section>
        </Page>
    );
};

export default Aree;
